import React, { AriaRole } from 'react';

import * as colors from '@hub/design-tokens/colors';
import { IOffsetProps } from '@ui/offset';

export enum TypographyType {
  HEADING_L = 'heading_l',
  HEADING_M = 'heading_m',
  HEADING_S = 'heading_s',
  HEADING_XS = 'heading_xs',
  LABEL = 'label',
  BODY_L_REGULAR = 'body_l_regular',
  BODY_L_BOLD = 'body_l_bold',
  BODY_M_BOLD = 'body_m_bold',
  BODY_M_REGULAR = 'body_m_regular',
  BODY_S = 'body_s',
  BODY_XS = 'body_xs',
  BODY_XS_BOLD = 'body_xs_bold',
}

export interface ITypographyProps extends Partial<IOffsetProps> {
  children: React.ReactNode;
  /**
   * @description
   * Defines what HTML tag will be rendered.
   * @default p
   */
  as?: keyof JSX.IntrinsicElements;
  /**
   * @description
   * `Type` indicates font family, font size and line height at the same time.
   */
  type: TypographyType;
  /**
   * @description
   * text-align
   */
  align?: 'center' | 'left' | 'right';
  textDecoration?: 'underline' | 'overline' | 'line-through' | 'none';
  textTransform?: 'capitalize' | 'lowercase' | 'uppercase' | 'none';
  color?: (typeof colors)[keyof typeof colors] | string;
  /**
   * @default normal
   */
  fontStyle?: 'normal' | 'italic';
  /**
   * @description
   * Text overflow parameter
   * Sometimes text can take more width than its container and overflow it.
   * This parameter allows to choose the way how this text should be displayed
   *
   * `truncate` - text will be truncated with an ellipsis at the end to fit the container width
   *
   * `wrap` - text will be wrapped to the next lines to fit the container width.
   *         `overflow-wrap: anywhere` CSS rule will be used to prevent overflowing by long  words
   * `overflow` - text will stay as it is even if it takes more width than its container
   *
   * @default 'overflow'
   */
  overflow?: 'truncate' | 'wrap' | 'overflow';
  /**
   * @description
   * If provided a number, it will truncate multiple lines of text. It works in combination with `overflow="truncate"`.
   */
  lineClamp?: number;
  id?: string;
  htmlFor?: string;
  role?: AriaRole;
}
