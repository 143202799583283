import styled from 'styled-components';

export const MainContent = styled.main`
  margin-left: var(--main-navigation-desktop-width);
  width: calc(100vw - var(--main-navigation-desktop-width));
  max-width: 100%;
  min-height: 100vh;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100vw;
    min-height: unset; 
  }
`;
