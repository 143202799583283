import { IDropdownOption } from '@ui/dropdown';

export type Locale = 'en' | 'nl' | 'fr';

export type LanguageOption = IDropdownOption & { value: Locale };

export enum LanguageEnum {
  EN = 'en',
  NL = 'nl',
  FR = 'fr',
}
