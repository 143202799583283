import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { SUPPORTED_LOCALES, DEFAULT_LOCALE, LOCAL_STORAGE_LOCALE_KEY } from '@bootstrap/constants/locale';
import { useLocalStorage } from '@bootstrap/hooks/useStorage';
import { LanguageEnum, Locale } from '@bootstrap/types/locale';
import { getLocaleFromNavigator } from '@bootstrap/utils/locale';
import { Select } from '@ui/select';

type LocaleFormValues = {
  locale: { label: string; value: Locale } | null;
};

export interface ILocaleSwitcherPops {
  locales?: LanguageEnum[];
  /**
   * @description
   * Pass function to save a locale on BE.
   */
  onChange?: (locale: Locale) => void;
  showLabel?: boolean;
}

export const LocaleSwitcher = ({ locales = SUPPORTED_LOCALES, onChange, showLabel = true }: ILocaleSwitcherPops) => {
  const { formatMessage } = useIntl();
  const localeOptions = locales.map((locale) => ({
    value: locale,
    label: formatMessage({ id: `languages.${locale}` }),
  }));

  const [locale, setLocale] = useLocalStorage<Locale>(
    LOCAL_STORAGE_LOCALE_KEY,
    getLocaleFromNavigator(locales) || DEFAULT_LOCALE,
  );
  const values: LocaleFormValues = {
    locale: localeOptions.find((item) => item.value === locale) ?? null,
  };
  const methods = useForm<LocaleFormValues>({
    defaultValues: values,
    values: values,
  });

  return (
    <FormProvider {...methods}>
      <form>
        <Select<NonNullable<LocaleFormValues['locale']>, false>
          label={showLabel ? <FormattedMessage id="field.language" /> : undefined}
          options={localeOptions}
          name="locale"
          registerOptions={{
            onChange(event) {
              const value = event.target.value?.value;
              setLocale(value);
              onChange?.(value);
            },
          }}
        />
      </form>
    </FormProvider>
  );
};
