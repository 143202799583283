import FocusLock from 'react-focus-lock';
import { Popover as ReactTinyPopover } from 'react-tiny-popover';

import { useKeydown } from '@bootstrap/hooks/useKeydown';

import { PopoverStyle } from './Popover.styles';
import { IPopoverProps } from './Popover.types';

export const Popover = ({ trigger, content, ...props }: IPopoverProps) => {
  useKeydown('Escape', props.onClickOutside);

  return (
    <ReactTinyPopover
      {...props}
      padding={8}
      content={
        // eslint-disable-next-line jsx-a11y/no-autofocus
        <FocusLock returnFocus autoFocus>
          <PopoverStyle>{content}</PopoverStyle>
        </FocusLock>
      }
      aria-expanded={props.isOpen}
      transformMode="relative"
    >
      {trigger}
    </ReactTinyPopover>
  );
};
