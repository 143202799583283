import styled from 'styled-components';

export const ControlVariableLabelStyles = styled.label`
  color: var(--color-neutral-800);
  font-family: var(--typography-body);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background: #f4f09d;
  padding: 4px 8px;
  border-radius: var(--border-radius-default);
`;
