import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as Chat } from '@ui/assets/icons/chat.svg';
import { Button } from '@ui/button';

export const LiveChatButton = ({ fullWidth = false }: { fullWidth: boolean }) => {
  const toggleTrengoWidget = useCallback((show: boolean) => {
    const widgetElement = document.getElementById('trengo-web-widget');
    if (widgetElement) widgetElement.style.display = show ? '' : 'none';
  }, []);

  const openChat = useCallback(() => {
    toggleTrengoWidget(true);
    window.Trengo.Api.Widget.render();
    window.Trengo.Api.Widget.open('chat');
  }, [toggleTrengoWidget]);

  useEffect(() => {
    toggleTrengoWidget(false);
    window.Trengo.on_close = () => {
      toggleTrengoWidget(false);
    };

    return () => {
      toggleTrengoWidget(false);
    };
  }, [toggleTrengoWidget]);

  return (
    <Button onClick={openChat} variant="secondary" iconLeft={<Chat />} fullWidth={fullWidth}>
      <FormattedMessage id="action.liveChat" />
    </Button>
  );
};
