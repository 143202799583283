import { SUPPORTED_LOCALES, LOCAL_STORAGE_LOCALE_KEY } from '@bootstrap/constants/locale';
import { LanguageEnum, Locale } from '@bootstrap/types/locale';

export const getLocaleFromLocalStorage = (supportedLocales: LanguageEnum[] = SUPPORTED_LOCALES): Locale | null => {
  const storageLocale = localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY);

  try {
    return supportedLocales.find((item) => storageLocale && item === JSON.parse(storageLocale)) ?? null;
  } catch {
    return null;
  }
};

export const getLocaleFromNavigator = (supportedLocales: LanguageEnum[] = SUPPORTED_LOCALES): Locale | null => {
  /**
   * This will return the first preferred allowed language from browser Navigator
   *
   * Example:
   *   languages: ["ja-JP", "en-US", "nl-Nl", "fr-FR"]
   *   allowed: ["en", "nl", "fr"]
   *   return: "en"
   */

  const found = window.navigator.languages
    .map((language) => language.toLowerCase().split('-')[0]) // Get lang code (Ex: 'en' from 'en-US')
    .find((locale) => supportedLocales.find((item) => item === locale));

  return found ? (found as Locale) : null;
};
