import { ApolloClient, ApolloProvider, InMemoryCache, ApolloLink } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { createContext, ReactElement } from 'react';

import { UseMagicLinkAuthContext } from '@debtor-portal/modules/auth/MagicLinkAuthProvider';
import DefaultDesktopLogo from '@ui/assets/img/finqle-logo-text.svg';
import DefaultMobileLogo from '@ui/assets/img/finqle-logo.svg';

interface IContextProps {
  desktopLogo: string;
  mobileLogo: string;
  isDefaultLogo: boolean;
}

const DebtorPortalApiContext = createContext<IContextProps>({
  desktopLogo: DefaultDesktopLogo,
  mobileLogo: DefaultMobileLogo,
  isDefaultLogo: true,
});

interface IProviderProps {
  apiUrl: string;
  children: ReactElement;
}

export const DebtorPortalApiProvider = ({ apiUrl, children }: IProviderProps) => {
  const { token } = UseMagicLinkAuthContext();
  if (!token) return children;

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: apiUrl,
      headers: {
        authorization: `Bearer ${token}`,
      },
    }) as unknown as ApolloLink,
  });
  return (
    <DebtorPortalApiContext.Provider
      value={{
        desktopLogo: DefaultDesktopLogo,
        mobileLogo: DefaultMobileLogo,
        isDefaultLogo: true,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </DebtorPortalApiContext.Provider>
  );
};

export const DebtorPortalApiConsumer = ({ children }: { children: (context: IContextProps) => React.ReactNode }) => (
  <DebtorPortalApiContext.Consumer>{(context) => children(context)}</DebtorPortalApiContext.Consumer>
);
