import { useCallback, useEffect, useState } from 'react';

import { captureException } from '@bootstrap/utils/sentry';

const LocalStorageUpdatedEventName = 'LocalStorageUpdatedEvent';
const SessionStorageUpdatedEventName = 'SessionStorageUpdatedEvent';

function useStorage<T>(storage: Storage, storageUpdatedEventName: string, key?: string, initialValue?: T) {
  const getValueFromStorage = () => {
    if (!key) return initialValue;

    try {
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    return getValueFromStorage();
  });

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      if (!key) return;
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        if (typeof window !== 'undefined') {
          storage.setItem(key, JSON.stringify(valueToStore));
        }
        window.dispatchEvent(new Event(storageUpdatedEventName));
      } catch (error) {
        captureException(error);
      }
    },
    [key, storage, storedValue],
  );

  useEffect(() => {
    const listenStorageChange = () => {
      setStoredValue(getValueFromStorage());
    };
    window.addEventListener(storageUpdatedEventName, listenStorageChange);
    return () => window.removeEventListener(storageUpdatedEventName, listenStorageChange);
  }, []);

  return [storedValue, setValue] as const;
}

export function useLocalStorage<T>(key?: string, initialValue?: T) {
  return useStorage(localStorage, LocalStorageUpdatedEventName, key, initialValue);
}

export function useSessionStorage<T>(key: string, initialValue: T) {
  return useStorage(sessionStorage, SessionStorageUpdatedEventName, key, initialValue);
}
