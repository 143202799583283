import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ColorPrimary700 } from '@hub/design-tokens/colors';
import { Spinner } from '@ui/spinner';
import { TypographyType } from '@ui/typography';

const PageLoaderStyles = styled.div`
  height: 100vh;
  display: grid;
  place-content: center;
`;

export const PageLoader = () => {
  return (
    <PageLoaderStyles>
      <Spinner
        size="lg"
        color={ColorPrimary700}
        type={TypographyType.HEADING_L}
        message={<FormattedMessage id="message.loading" />}
      />
    </PageLoaderStyles>
  );
};
