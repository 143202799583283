import { forwardRef } from 'react';
import styled from 'styled-components';

import { EN_DASH } from '@bootstrap/constants/unicodeCharacters';
import { ColorNeutral600, ColorNeutral800 } from '@hub/design-tokens';
import { ReactComponent as MoreVert } from '@ui/assets/icons/more_vert.svg';
import { Grid } from '@ui/grid';
import { Typography, TypographyType } from '@ui/typography';

export const UserProfileWrapper = styled.button<{ hasButton: boolean }>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  width: 100%;
  padding: 16px;
  margin-left: -16px;
  margin-right: -16px;
  background: var(--color-white);
  border: none;
  border-top: 1px solid var(--color-neutral-200);
  cursor: ${({ hasButton }) => (hasButton ? 'default' : 'pointer')};
  &:hover {
    background: ${({ hasButton }) => (hasButton ? 'inherit' : 'var(--color-neutral-100)')};
  }
  &:active {
    background: ${({ hasButton }) => (hasButton ? 'inherit' : 'var(--color-neutral-200)')};
  }
  &:focus-visible {
    outline: auto;
    outline-offset: -2px;
  }
`;

interface IUserProfileProps {
  userName: string;
  userCompany?: string | JSX.Element;
  addon?: JSX.Element;
  onClick?: () => void;
}

export const UserProfile = forwardRef<HTMLButtonElement, IUserProfileProps>(function UserProfile(
  { userName, userCompany, addon, onClick },
  ref,
) {
  return (
    <UserProfileWrapper hasButton={!!addon} as={addon ? 'div' : 'button'} onClick={onClick} ref={ref}>
      <Grid template="1fr">
        <Typography type={TypographyType.BODY_S} color={ColorNeutral800} overflow="truncate">
          {userName ?? EN_DASH}
        </Typography>
        <Typography type={TypographyType.BODY_S} color={ColorNeutral600} overflow="truncate">
          {userCompany ?? EN_DASH}
        </Typography>
      </Grid>
      {addon ?? <MoreVert color={ColorNeutral800} />}
    </UserProfileWrapper>
  );
});
