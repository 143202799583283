import { TypographyStyles } from './Typography.styles';
import { ITypographyProps } from './Typography.types';

export const Typography = ({
  children,
  type,
  as = 'p',
  align = 'left',
  textDecoration = 'none',
  textTransform = 'none',
  color,
  fontStyle = 'normal',
  overflow = 'overflow',
  ...htmlAttributes
}: ITypographyProps) => {
  return (
    <TypographyStyles
      as={as}
      type={type}
      align={align}
      textDecoration={textDecoration}
      textTransform={textTransform}
      color={color}
      overflow={overflow}
      fontStyle={fontStyle}
      {...htmlAttributes}
    >
      {children}
    </TypographyStyles>
  );
};
