import styled from 'styled-components';

import {
  ColorPositive500,
  ColorNegative500,
  ColorPrimary500,
  ColorPositive600,
  ColorNegative600,
  ColorPrimary600,
  ColorPositive700,
  ColorNegative700,
  ColorPrimary700,
} from '@hub/design-tokens/colors';

import { IToastProps } from './Toast.types';

const backgrounds = {
  info: ColorPrimary500,
  success: ColorPositive500,
  error: ColorNegative500,
};

const hoverBackgrounds = {
  info: ColorPrimary600,
  success: ColorPositive600,
  error: ColorNegative600,
};

const activeBackgrounds = {
  info: ColorPrimary700,
  success: ColorPositive700,
  error: ColorNegative700,
};

export const ToastStyles = styled.div<Pick<IToastProps, 'type'>>`
  width: min(480px, 100%);
  padding: 16px;
  background: ${({ type }) => `${backgrounds[type]}`};
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-large);
`;

export const CrossButton = styled.button<{ toastType: IToastProps['type'] }>`
  display: inline-flex;
  padding: 0;
  appearance: unset;
  border: 1px solid transparent;
  border-radius: var(--border-radius-default);
  background-color: transparent;
  cursor: pointer;

  > svg {
    color: var(--color-white);
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ toastType }) => hoverBackgrounds[toastType]};
    }
  }

  &:active {
    background-color: ${({ toastType }) => activeBackgrounds[toastType]};
  }

  &:focus-visible {
    background-color: ${({ toastType }) => hoverBackgrounds[toastType]};
    border: 1px solid var(--color-white);
  }
`;
