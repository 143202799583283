import styled, { keyframes, css } from 'styled-components';

import { ReactComponent as Spinner } from '@ui/assets/icons/spinner.svg';

import { ISpinnerProps } from './Spinner.types';

const spinnerAnimation = keyframes`
  8.3% {
    transform:rotate(30deg)
  }
  16.6% {
    transform:rotate(60deg)
  }
  25% {
    transform:rotate(90deg)
  }
  33.3% {
    transform:rotate(120deg)
  }
  41.6% {
    transform:rotate(150deg)
  }
  50% {
    transform:rotate(180deg)
  }
  58.3% {
    transform:rotate(210deg)
  }
  66.6% {
    transform:rotate(240deg)
  }
  75% {
    transform:rotate(270deg)
  }
  83.3% {
    transform:rotate(300deg)
  }
  91.6% {
    transform:rotate(330deg)
  }
  100% {
    transform:rotate(360deg)
  }
`;

const sizes = {
  xs: css`
    width: 14px;
    height: 14px;
  `,
  sm: css`
    width: 16px;
    height: 16px;
  `,
  md: css`
    width: 19px;
    height: 19px;
  `,
  lg: css`
    width: 24px;
    height: 24px;
  `,
};

export const SpinnerStyles = styled(Spinner)<ISpinnerProps>`
  ${({ size = 'sm' }) => sizes[size]};
  color: ${({ color = 'var(--color-neutral-800)' }) => color};
  animation: ${spinnerAnimation} 1s step-end infinite;
`;
