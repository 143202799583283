import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { NotFoundPage } from '@bootstrap/components/NotFoundPage';
import { FEATURE_FLAGS } from '@debtor-portal/configs/featureFlags';
import { DebtorPortalLogout } from '@debtor-portal/modules/auth/DebtorPortalLogout';

const Dashboard = lazy(() => import('@debtor-portal/pages/Dashboard'));
const Invoices = lazy(() => import('@debtor-portal/pages/InvoicesPage'));
const InvoiceDetail = lazy(() => import('@debtor-portal/pages/InvoiceDetailsPage'));
const CollectiveInvoices = lazy(() => import('@debtor-portal/pages/CollectiveInvoicesPage'));
const CollectiveInvoiceDetail = lazy(() => import('@debtor-portal/pages/CollectiveInvoiceDetailsPage'));
const BillingRequests = lazy(() => import('@debtor-portal/pages/BillingRequestsPage'));
const BillingRequestDetail = lazy(() => import('@debtor-portal/pages/BillingRequestDetailsPage'));
const Profile = lazy(() => import('@debtor-portal/pages/ProfilePage'));
const DirectDebitAuthorization = lazy(() => import('@debtor-portal/pages/DirectDebitAuthorizationPage'));
const Suppliers = lazy(() => import('@debtor-portal/pages/SuppliersPage'));
const SupplierDetails = lazy(() => import('@debtor-portal/pages/SupplierDetailsPage'));

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/invoices" element={<Invoices />} />
      <Route path="/invoices/:id" element={<InvoiceDetail />} />
      <Route path="/collective-invoices" element={<CollectiveInvoices />} />
      <Route path="/collective-invoices/:id" element={<CollectiveInvoiceDetail />} />
      <Route path="/direct-debit" element={<DirectDebitAuthorization />} />
      {FEATURE_FLAGS.SUPPLIERS_PAGE && (
        <Route path="/suppliers">
          <Route path="/suppliers" element={<Suppliers />} />
          <Route path="/suppliers/:supplierId/*" element={<SupplierDetails />} />
        </Route>
      )}
      <Route path="/billing-requests">
        <Route path="/billing-requests" element={<BillingRequests />} />
        <Route path="/billing-requests/:id/*" element={<BillingRequestDetail />} />
      </Route>
      <Route path="/profile" element={<Profile />} />
      <Route path="/logout" element={<DebtorPortalLogout />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
