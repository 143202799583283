import * as Sentry from '@sentry/react';
import { browserTracingIntegration, replayIntegration } from '@sentry/react';

import { isLocalDevelopment } from '@bootstrap/constants/env';

let isInitialized = false;

export const initializeSentry = (dsn: string) => {
  if (!isLocalDevelopment && !isInitialized && dsn) {
    Sentry.init({
      environment: process.env.NX_PUBLIC_ENV,
      dsn,
      integrations: [browserTracingIntegration(), replayIntegration()],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    isInitialized = true;
  }
};

// TODO: fix `extra` types
export const captureException = (e: Error | unknown, extra?: any) => {
  if (isInitialized) {
    Sentry.captureException(e, extra);
  }
};
