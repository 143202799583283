import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';

const textStyles = css`
  font-family: var(--typography-body);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const ReactSelectStyles = styled(ReactSelect)`
  .react-select__control {
    flex-wrap: nowrap;
    height: ${({ isMulti }) => (isMulti ? 'auto' : '40px')};
    width: 100%;
    margin: 0;
    background-color: var(--color-white);
    border: 1px solid var(--color-neutral-300);
    border-radius: var(--border-radius-default);
    box-shadow: none;

    @media (hover: hover) and (pointer: fine) {
      &:hover:not(.react-select__control--is-focused) {
        border: 1px solid var(--color-neutral-500);
      }
    }

    ${({ isErrorShown }) => isErrorShown && 'border: 1px solid var(--color-negative-600)!important;'};
  }

  .react-select__control--is-disabled {
    background: var(--color-neutral-100);
    border: 1px solid var(--color-neutral-300);
  }

  .react-select__control--is-focused {
    border: 1px solid var(--color-primary-500);
  }

  .react-select__value-container {
    ${textStyles}
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--color-neutral-800);
  }

  .react-select__placeholder {
    ${textStyles}
    color: var(--color-neutral-500);
  }

  .react-select__indicators {
    .react-select__clear-indicator {
      padding: 8px;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
        color: var(--color-neutral-600);
      }
    }

    .react-select__indicator-separator {
      width: 1px;
      background: var(--color-neutral-300);
      display: ${({ isMulti, hasValues }) => (isMulti && hasValues ? 'block' : 'none')};
    }

    .react-select__dropdown-indicator {
      padding: 8px;
      color: var(--color-neutral-600);

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .react-select__menu {
    margin-top: 0;
    background: var(--color-white);
    box-shadow: var(--shadow-large);
    border: 1px solid var(--color-neutral-300);
    width: fit-content;
    min-width: 100%;
  }

  .react-select__menu-list {
    padding: 8px 0;
    max-height: 420px;
    border-radius: var(--border-radius-default);
  }

  .react-select__option {
    ${textStyles}
    color: var(--color-neutral-800);
    padding: 8px;
    background-color: var(--color-white);

    @media (hover: hover) and (pointer: fine) {
      &:hover:not(.react-select__option--is-selected, .react-select__option--is-disabled) {
        background-color: var(--color-neutral-100);
      }
    }
  }

  .react-select__option--is-selected {
    background-color: var(--color-primary-200);
  }

  .react-select__option--is-disabled {
    color: var(--color-neutral-500);
  }

  .react-select__option--is-focused:not(.react-select__option--is-selected) {
    background-color: var(--color-neutral-100);
  }

  .react-select__multi-value {
    padding: 4px;
    gap: 4px;
    border-radius: var(--border-radius-default);

    .react-select__multi-value__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 0;
      color: var(--color-neutral-800);
    }

    .react-select__multi-value__remove {
      padding: 0;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
        color: var(--color-neutral-800);
      }

      &:hover {
        background: var(--color-neutral-400);
      }

      &:active {
        background: var(--color-neutral-500);
      }
    }
  }
`;
