import { ColorWhite } from '@hub/design-tokens/colors';
import { ReactComponent as CheckCircle } from '@ui/assets/icons/check-circle.svg';
import { ReactComponent as Close } from '@ui/assets/icons/close.svg';
import { ReactComponent as Error } from '@ui/assets/icons/error.svg';
import { ReactComponent as Info } from '@ui/assets/icons/info.svg';
import { Grid } from '@ui/grid';
import { Typography, TypographyType } from '@ui/typography';

import { ToastStyles, CrossButton } from './Toast.styles';
import { IToastProps } from './Toast.types';

const icons = {
  info: Info,
  success: CheckCircle,
  error: Error,
};

export const Toast = ({ message, description, type, onClose }: IToastProps) => {
  const Icon = icons[type];

  return (
    <ToastStyles type={type}>
      <Grid gap={8} template="24px 1fr 24px" align="start" justify="start">
        <Icon color={ColorWhite} aria-hidden="true" />
        <Grid gap={8}>
          <Typography type={TypographyType.BODY_M_BOLD} color={ColorWhite}>
            {message}
          </Typography>
          {description && (
            <Typography type={TypographyType.BODY_S} color={ColorWhite} as="div">
              {description}
            </Typography>
          )}
        </Grid>
        {onClose && (
          <CrossButton toastType={type} aria-label="Close" onClick={onClose}>
            <Close />
          </CrossButton>
        )}
      </Grid>
    </ToastStyles>
  );
};
