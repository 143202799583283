import mixpanel from 'mixpanel-browser';

import { isProduction } from '@bootstrap/constants/env';

import { captureException } from './sentry';

let isInitialized = false;

export const initializeMixpanel = (mixpanelId: string) => {
  if (mixpanelId && isProduction) {
    try {
      mixpanel.init(mixpanelId);
      isInitialized = true;
    } catch (e) {
      captureException(e);
    }
  }
};

export const track = (eventName: string, properties?: Record<string, string>) => {
  if (isInitialized) {
    mixpanel.track(eventName, properties);
  }
};
