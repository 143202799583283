import { useEffect, useState } from 'react';

import { LiveChatButton } from './LiveChatButton';
declare global {
  interface Window {
    Trengo: any;
  }
}

export const LiveChat = ({ fullWidth = false }: { fullWidth?: boolean }) => {
  const [trengoLoaded, setTrengoLoaded] = useState(!!window.Trengo);

  useEffect(() => {
    if (!trengoLoaded) {
      window.Trengo = window.Trengo || {};
      window.Trengo.key = 'RAvk7FMwgHWnRgsgxW7h';

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://static.widget.trengo.eu/embed.js';
      document.getElementsByTagName('head')[0]?.appendChild(script);

      window.Trengo.on_ready = () => {
        setTrengoLoaded(true);
      };
    }
  }, [trengoLoaded]);

  return trengoLoaded ? <LiveChatButton fullWidth={fullWidth} /> : null;
};
