import styled, { css } from 'styled-components';

export interface IBackdropProps {
  isOpen: boolean;
  onClose?: () => void;
  /**
   * @default true
   */
  closeOnClickOutside?: boolean;
}

const BackdropStyles = styled.div<Pick<IBackdropProps, 'isOpen'>>`
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-black);
  opacity: 0.1;
  transition: opacity 0.3s ease;
  pointer-events: none;

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 0.24;
      pointer-events: auto;
      z-index: -1;

      @media (max-width: 768px) {
        z-index: var(--z-index-modal);
      }
    `}
`;

/**
 * @description
 * Backdrop must always go before the content so z-indices work correctly.
 */
export const Backdrop = ({ onClose, isOpen, closeOnClickOutside = true }: IBackdropProps) => {
  return <BackdropStyles isOpen={isOpen} onClick={closeOnClickOutside && onClose ? onClose : undefined} />;
};
