import styled from 'styled-components';

import { IDrawerProps } from '@ui/drawer';

export const StickyFooterContainerStyles = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - var(--main-navigation-mobile-top-bar-height));

    @supports (min-height: 100dvh) {
      min-height: calc(100dvh - var(--main-navigation-mobile-top-bar-height));
    }
  }
`;

export const StickyFooterStyles = styled.div<{ padding?: IDrawerProps['padding'] }>`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ padding }) =>
    padding !== undefined
      ? typeof padding === 'number'
        ? `${padding}px`
        : padding?.map((p) => (p !== null ? `${p}px` : p)).join(' ')
      : '16px'};
  border-top: 1px solid var(--color-neutral-300);
  background: var(--color-white);
  box-shadow: var(--shadow-default);
`;
