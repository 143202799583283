import { Suspense, ReactNode } from 'react';
import styled from 'styled-components';

import { ErrorBoundary } from '@bootstrap/components/error-boundary';
import { AppRoutesProps } from '@bootstrap/configs/route-access';
import DefaultDesktopLogo from '@ui/assets/img/finqle-logo-text.svg';
import DefaultMobileLogo from '@ui/assets/img/finqle-logo.svg';
import { Grid } from '@ui/grid';
import { LiveChat } from '@ui/live-chat';
import { MainNavigation, MainContent } from '@ui/main-navigation';

import { PageLoader } from './PageLoader';

interface IBaseTemplateProps {
  routes: AppRoutesProps[];
  children: ReactNode;
  desktopLogo: string;
  mobileLogo: string;
  userProfile: ReactNode;
  isMobileMenuOpen: boolean;
  toggleMobileMenu: () => void;
  isDefaultLogo: boolean;
  isLiveChatEnabled?: boolean;
}

const UseProfileWrapper = styled(Grid)`
  margin: 0 -16px -16px;
`;

export const BaseTemplate = ({
  children,
  desktopLogo = DefaultDesktopLogo,
  mobileLogo = DefaultMobileLogo,
  routes,
  isLiveChatEnabled,
  isDefaultLogo,
  isMobileMenuOpen = false,
  toggleMobileMenu,
  userProfile,
}: IBaseTemplateProps) => (
  <Grid>
    <MainNavigation
      toggleMobileMenu={toggleMobileMenu}
      isMobileMenuOpen={isMobileMenuOpen}
      desktopLogo={desktopLogo}
      mobileLogo={mobileLogo}
      isDefaultLogo={isDefaultLogo}
      alt="Finqle logo"
      routes={routes}
      footer={
        <>
          {isLiveChatEnabled && <LiveChat fullWidth />}

          <UseProfileWrapper gap={16} template="1fr" justify="center">
            {userProfile}
          </UseProfileWrapper>
        </>
      }
    />
    <MainContent>
      <Suspense fallback={<PageLoader />}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Suspense>
    </MainContent>
  </Grid>
);
