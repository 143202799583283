import { useEffect, useId, useRef } from 'react';
import FocusLock from 'react-focus-lock';
import { useIntl } from 'react-intl';

import { useLockBodyScroll } from '@bootstrap/hooks';
import { useMobile } from '@bootstrap/hooks/useMedia';
import { ColorNeutral600 } from '@hub/design-tokens/colors';
import { ReactComponent as Close } from '@ui/assets/icons/close.svg';
import { Backdrop } from '@ui/backdrop';
import { Button } from '@ui/button';
import { Flex } from '@ui/flex';
import { Offset } from '@ui/offset';
import { Portal } from '@ui/portal';
import { StickyFooter } from '@ui/sticky-footer';
import { Typography, TypographyType } from '@ui/typography';

import { DrawerContainerStyles, DrawerStyles, DrawerContentStyles, DrawerHeader, DrawerContent } from './Drawer.styles';
import { IDrawerProps } from './Drawer.types';

export const Drawer = ({
  children,
  onClose,
  isOpen,
  size,
  fullscreen,
  title,
  subtitle,
  footer,
  padding,
}: IDrawerProps) => {
  const { formatMessage } = useIntl();
  useLockBodyScroll(isOpen);
  const isMobile = useMobile();
  const id = useId();
  const ariaDescribedBy = subtitle ? `${id}-subtitle` : undefined;

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const node = ref.current;

    if (!node) return;
    function onKeypress(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onClose();
      }
    }
    node.addEventListener('keydown', onKeypress);
    return () => node.removeEventListener('keydown', onKeypress);
  }, [onClose]);

  return (
    <Portal>
      <DrawerContainerStyles aria-hidden={isOpen ? 'false' : 'true'}>
        <Backdrop isOpen={isOpen} onClose={onClose} />
        <FocusLock disabled={!isOpen} returnFocus>
          <DrawerStyles
            isOpen={isOpen}
            size={size}
            fullscreen={fullscreen}
            role="dialog"
            aria-labelledby={id}
            aria-describedby={ariaDescribedBy}
            ref={ref}
          >
            <DrawerContentStyles size={size} fullscreen={fullscreen} footer={footer}>
              <DrawerHeader>
                <Flex gap={isMobile ? 8 : 16} align="center" justify={title ? 'space-between' : 'end'}>
                  {title && (
                    <Typography type={isMobile ? TypographyType.HEADING_S : TypographyType.HEADING_M} as="h2" id={id}>
                      {title}
                    </Typography>
                  )}
                  <Button
                    variant="secondary"
                    iconLeft={<Close aria-hidden />}
                    onClick={onClose}
                    aria-label={formatMessage({ id: 'action.close' })}
                  />
                </Flex>
                {subtitle && (
                  <Offset offsetTop={8}>
                    <Typography
                      type={isMobile ? TypographyType.HEADING_XS : TypographyType.HEADING_S}
                      color={ColorNeutral600}
                      id={ariaDescribedBy}
                    >
                      {subtitle}
                    </Typography>
                  </Offset>
                )}
              </DrawerHeader>
              {isOpen && <DrawerContent padding={padding}>{children}</DrawerContent>}
              {footer && <StickyFooter padding={padding}>{footer}</StickyFooter>}
            </DrawerContentStyles>
          </DrawerStyles>
        </FocusLock>
      </DrawerContainerStyles>
    </Portal>
  );
};
