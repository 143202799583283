import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import { IntlProvider } from 'react-intl';

import { IApiContextProps } from '@bootstrap/context/api-provider';
import { DebtorPortalApiProvider, DebtorPortalApiConsumer } from '@debtor-portal/modules/auth/DebtorPortalApiProvider';
import { MagicLinkAuthProvider } from '@debtor-portal/modules/auth/MagicLinkAuthProvider';
import { CompanyContextProvider } from '@debtor-portal/modules/company/Company.context';
import { Spinner } from '@ui/spinner';
import { Toaster } from '@ui/toast';

import { AppRouter } from './AppRouter';
import config from './constants';
import { MainTemplate } from './MainTemplate';

// Set Monday the first day of the week for en locale
dayjs.extend(utc);
dayjs.locale({
  ...en,
  weekStart: 1,
});

interface IAppProps {
  locale: string;
  messages: Record<string, string>;
}

export const App = ({ locale, messages }: IAppProps) => {
  return messages ? (
    <IntlProvider locale={locale} messages={messages}>
      <MagicLinkAuthProvider>
        <DebtorPortalApiProvider apiUrl={config.GRAPHQL_URL}>
          <DebtorPortalApiConsumer>
            {(props: IApiContextProps) => (
              <CompanyContextProvider>
                <MainTemplate {...props}>
                  <AppRouter />
                  <Toaster />
                </MainTemplate>
              </CompanyContextProvider>
            )}
          </DebtorPortalApiConsumer>
        </DebtorPortalApiProvider>
      </MagicLinkAuthProvider>
    </IntlProvider>
  ) : (
    <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
      <Spinner size="md" message="Loading..." />
    </div>
  );
};
