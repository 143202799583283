import styled, { CSSProperties } from 'styled-components';

import { IOffsetProps } from '@ui/offset';
import { offsetStyles } from '@ui/offset/Offset';

export interface IFlexProps extends Partial<IOffsetProps> {
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  justify?: 'center' | 'start' | 'end' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch';
  align?: 'center' | 'start' | 'end' | 'stretch';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  grow?: CSSProperties['flexGrow'];
  gap?: number;
}

export interface IFlexItemProps {
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
  alignSelf?: 'start' | 'end' | 'center' | 'stretch';
  basis?: CSSProperties['flexBasis'];
  grow?: CSSProperties['flexGrow'];
  shrink?: CSSProperties['flexShrink'];
}

const FlexContainerStyles = styled.div<IFlexProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  flex-wrap: ${({ wrap }) => wrap};
  gap: ${({ gap = 0 }) => `${gap}px`};
  flex-grow: ${({ grow }) => grow};
  ${offsetStyles}
`;

const FlexItemStyles = styled.div<IFlexItemProps>`
  flex-basis: ${({ basis }) => basis};
  flex-grow: ${({ grow }) => grow};
  flex-shrink: ${({ shrink }) => shrink};
  align-self: ${({ alignSelf }) => alignSelf};
`;

export const Flex = ({ children, ...props }: IFlexProps) => {
  return <FlexContainerStyles {...props}>{children}</FlexContainerStyles>;
};

export const FlexItem = ({ children, ...props }: IFlexItemProps) => {
  return <FlexItemStyles {...props}>{children}</FlexItemStyles>;
};
