import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, matchPath, Link, useNavigate } from 'react-router-dom';

import { useMobile } from '@bootstrap/hooks/useMedia';
import { ColorNeutral600 } from '@hub/design-tokens/colors';
import { ReactComponent as Menu } from '@ui/assets/icons/menu.svg';
import { Button } from '@ui/button';
import { Drawer } from '@ui/drawer';
import { Offset } from '@ui/offset';
import { Typography, TypographyType } from '@ui/typography';

import {
  MainNavigationStyles,
  MainNavigationLogoStyles,
  MainNavigationMenuStyles,
  MainNavigationItemStyles,
  MainNavigationTopBar,
  MainNavigationMobileMenu,
  MainNavigationTitleStyles,
} from './MainNavigation.styles';
import { IMainNavigationRouteProps } from './MainNavigation.types';

const MainNavigationMenu = ({ routes, onClose }: { routes: IMainNavigationRouteProps[]; onClose?: () => void }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isMobile = useMobile();

  const isSomeSubrouteMatchPath = (route: IMainNavigationRouteProps): boolean => {
    return Boolean(route.subRoutes?.some((subRoute) => matchPath({ path: subRoute.path, end: false }, pathname)));
  };

  return (
    <MainNavigationMenuStyles role="menubar">
      {routes
        .filter((route) => !route.hidden)
        .map((route) => {
          const hasVisibleSubRoutes = route.subRoutes?.filter((subRoute) => !subRoute.hidden)?.length;
          if (hasVisibleSubRoutes) {
            return (
              <li key={route.path}>
                <MainNavigationTitleStyles>{route.title}</MainNavigationTitleStyles>
                <MainNavigationMenuStyles role="menu">
                  {route.subRoutes
                    ?.filter((subRoute) => !subRoute.hidden)
                    ?.map((subRoute) => {
                      return (
                        <li key={subRoute.path}>
                          {subRoute.icon ? (
                            <MainNavigationItemStyles
                              isMobile={isMobile}
                              as={isMobile ? 'button' : Link}
                              role="menuitem"
                              aria-current={
                                matchPath({ path: subRoute.path, end: false }, pathname) ? 'page' : undefined
                              }
                              to={subRoute.path}
                              onClick={() => {
                                navigate(subRoute.path);
                                onClose?.();
                              }}
                            >
                              {subRoute.icon}
                              {subRoute.title}
                            </MainNavigationItemStyles>
                          ) : (
                            <MainNavigationTitleStyles>{subRoute.title}</MainNavigationTitleStyles>
                          )}
                        </li>
                      );
                    })}
                </MainNavigationMenuStyles>
              </li>
            );
          }

          const isMatchPath = isSomeSubrouteMatchPath(route) || matchPath(route.path, pathname);

          return (
            <li key={route.path}>
              <MainNavigationItemStyles
                isMobile={isMobile}
                as={isMobile ? 'button' : Link}
                to={route.path}
                onClick={() => {
                  navigate(route.path);
                  onClose?.();
                }}
                role="menuitem"
                aria-current={isMatchPath ? 'page' : undefined}
              >
                {route.icon}
                {route.title}
              </MainNavigationItemStyles>
            </li>
          );
        })}
    </MainNavigationMenuStyles>
  );
};

const findRouteMobileTitleByPath = (routes: IMainNavigationRouteProps[], pathname: string) => {
  for (const route of routes) {
    if (route.path === pathname) {
      return route.title;
    }
    if (route.subRoutes) {
      for (const subroute of route.subRoutes) {
        if (subroute.path === pathname) {
          return subroute.title;
        }
      }
    }
  }
  return null;
};

export const MainNavigation = ({
  desktopLogo,
  mobileLogo,
  isDefaultLogo,
  alt,
  routes,
  footer,
  isMobileMenuOpen = false,
  toggleMobileMenu = () => null,
}: {
  desktopLogo: string;
  mobileLogo: string;
  alt: string;
  routes: IMainNavigationRouteProps[];
  footer: React.ReactNode;
  isMobileMenuOpen: boolean;
  isDefaultLogo: boolean;
  toggleMobileMenu: () => void;
}) => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const isMobile = useMobile();

  if (isMobile) {
    return (
      <>
        <MainNavigationTopBar>
          <Link to="/">
            <MainNavigationLogoStyles src={mobileLogo} alt={alt} height={48} width={48} />
          </Link>
          <Typography type={TypographyType.HEADING_S}>{findRouteMobileTitleByPath(routes, pathname)}</Typography>
          <Button
            variant="secondary"
            size="large"
            iconLeft={<Menu />}
            aria-label={formatMessage({ id: 'mainNavigation.openMenu' })}
            onClick={toggleMobileMenu}
          />
        </MainNavigationTopBar>
        <Drawer
          size="xl"
          isOpen={isMobileMenuOpen}
          onClose={toggleMobileMenu}
          title={<FormattedMessage id="field.menu" />}
        >
          <MainNavigationMobileMenu>
            <MainNavigationMenu routes={routes} onClose={toggleMobileMenu} />
            {footer}
          </MainNavigationMobileMenu>
        </Drawer>
      </>
    );
  }

  return (
    <MainNavigationStyles>
      <header>
        <MainNavigationLogoStyles src={desktopLogo} alt={alt} />
        {!isDefaultLogo && (
          <Offset offsetTop={8}>
            <Typography type={TypographyType.BODY_XS} color={ColorNeutral600}>
              <FormattedMessage id="field.poweredByFinqle" />
            </Typography>
          </Offset>
        )}
      </header>
      <MainNavigationMenu routes={routes} />
      {footer}
    </MainNavigationStyles>
  );
};
