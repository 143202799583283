import styled, { css } from 'styled-components';

import { offsetStyles } from '@ui/offset/Offset';

import { ITypographyProps, TypographyType } from './Typography.types';

const typeToStyle = {
  [TypographyType.HEADING_L]: css`
    font-family: var(--typography-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    color: ${({ theme }) => theme?.brandColor ?? 'var(--color-primary-700)'};
  `,
  [TypographyType.HEADING_M]: css`
    font-family: var(--typography-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme?.brandColor ?? 'var(--color-primary-700)'};
  `,
  [TypographyType.HEADING_S]: css`
    font-family: var(--typography-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme?.brandColor ?? 'var(--color-primary-700)'};
  `,
  [TypographyType.HEADING_XS]: css`
    font-family: var(--typography-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme?.brandColor ?? 'var(--color-primary-700)'};
  `,
  [TypographyType.LABEL]: css`
    font-family: var(--typography-body);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-neutral-600);
  `,
  [TypographyType.BODY_L_REGULAR]: css`
    font-family: var(--typography-body);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: var(--color-neutral-800);
  `,
  [TypographyType.BODY_L_BOLD]: css`
    font-family: var(--typography-body);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: var(--color-neutral-800);
  `,
  [TypographyType.BODY_M_BOLD]: css`
    font-family: var(--typography-body);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-neutral-800);
  `,
  [TypographyType.BODY_M_REGULAR]: css`
    font-family: var(--typography-body);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-neutral-800);
  `,
  [TypographyType.BODY_S]: css`
    font-family: var(--typography-body);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-neutral-800);
  `,
  [TypographyType.BODY_XS]: css`
    font-family: var(--typography-body);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-neutral-800);
  `,
  [TypographyType.BODY_XS_BOLD]: css`
    font-family: var(--typography-body);
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-neutral-800);
  `,
};

export const TypographyStyles = styled.p<ITypographyProps>`
  ${({ type }) => type && typeToStyle[type]}
  text-align: ${({ align }) => align};
  text-decoration: ${({ textDecoration }) => textDecoration};
  text-transform: ${({ textTransform }) => textTransform};
  font-style: ${({ fontStyle }) => fontStyle};
  color: ${({ color }) => color};
  margin: 0;
  padding: 0;
  ${({ overflow, lineClamp }) =>
    overflow === 'truncate' && !lineClamp
      ? css`
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `
      : overflow === 'wrap'
        ? css`
            overflow-wrap: anywhere;
            white-space: wrap;
          `
        : ''};
  ${offsetStyles}
  ${({ overflow, lineClamp }) =>
    overflow === 'truncate' &&
    lineClamp &&
    css`
      -webkit-line-clamp: ${lineClamp};
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;
