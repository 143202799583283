export const ColorWhite = "#ffffff";
export const ColorBlack = "#000000";
export const ColorFinqle = "#373a6f";
export const ColorPrimary100 = "#e2f3fd";
export const ColorPrimary200 = "#c0e8ff";
export const ColorPrimary300 = "#9ed8f9";
export const ColorPrimary400 = "#4eb6f3";
export const ColorPrimary500 = "#0492e5";
export const ColorPrimary600 = "#007bc2";
export const ColorPrimary700 = "#003d7a";
export const ColorPrimary800 = "#00264d";
export const ColorPrimaryDark = "#004990"; // deprecated
export const ColorPrimaryMild = "#99d3f5"; // deprecated
export const ColorPrimaryMilddark = "#5bacdc"; // deprecated
export const ColorPrimaryNeutral = "#9fd7f9"; // deprecated
export const ColorPrimaryNeutrallight = "#c7eaff"; // deprecated
export const ColorPrimaryLight = "#e2f3fd"; // deprecated
export const ColorPrimaryLighter = "#edf4f9"; // deprecated
export const ColorNeutral100 = "#f5f7f9";
export const ColorNeutral200 = "#e3e6e8";
export const ColorNeutral300 = "#d4d9dd";
export const ColorNeutral400 = "#b7c1c8";
export const ColorNeutral500 = "#959ea6";
export const ColorNeutral600 = "#6d7781";
export const ColorNeutral700 = "#3d464f";
export const ColorNeutral800 = "#212834";
export const ColorPositive200 = "#e4f2c8";
export const ColorPositive300 = "#c0db8e";
export const ColorPositive400 = "#80ba45";
export const ColorPositive500 = "#4a8a0a";
export const ColorPositive600 = "#3b6e08";
export const ColorPositive700 = "#0f4700";
export const ColorNegative200 = "#ffd8d1";
export const ColorNegative300 = "#ff8e7a";
export const ColorNegative400 = "#f8664c";
export const ColorNegative500 = "#d24025";
export const ColorNegative600 = "#ae2b17";
export const ColorNegative700 = "#690c00";
export const ColorWarning200 = "#ffeee0";
export const ColorWarning300 = "#ffc38f";
export const ColorWarning400 = "#feb95a";
export const ColorWarning500 = "#e7982b";
export const ColorWarning600 = "#df8d1c";
export const ColorWarning700 = "#af5506";
export const ColorGray800 = "#414153"; // deprecated
export const ColorGrayCoolDarker = "#b2b7bb"; // deprecated
export const ColorGrayCoolDark = "#d4d9dd"; // deprecated
export const ColorGrayCoolMild = "#e4e6e8"; // deprecated
export const ColorGrayCoolMildlight = "#f3f3f3"; // deprecated
export const ColorGrayCoolNeutral = "#ebf1f5"; // deprecated
export const ColorGrayCoolLight = "#f5f8fa"; // deprecated
export const ColorGrayWarmDarker = "#4b4b5f"; // deprecated
export const ColorGrayWarmDark = "#8c8c92"; // deprecated
export const ColorGrayWarmNeutral = "#a5a5ac"; // deprecated
export const ColorGrayWarmLight = "#d2d2d6"; // deprecated
export const ColorConfirmationDark = "#3f6400"; // deprecated
export const ColorConfirmationNeutral = "#7fa844"; // deprecated
export const ColorConfirmationLight = "#c0db8e"; // deprecated
export const ColorNotificationDark = "#cc6d19"; // deprecated
export const ColorNotificationNeutral = "#dda16d"; // deprecated
export const ColorNotificationLight = "#ffc38f"; // deprecated
export const ColorAlertDark = "#690c00"; // deprecated
export const ColorAlertNeutral = "#dd614a"; // deprecated
export const ColorAlertLight = "#ff826c"; // deprecated
