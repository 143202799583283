import styled from 'styled-components';

import * as colors from '@hub/design-tokens/colors';

export interface IDividerProps {
  /**
   * @default default
   */
  spacing?: 'default' | 'medium' | 'large' | 'none';
  color?: (typeof colors)[keyof typeof colors];
  negativeMargin?: '-8px' | '-16px' | '-24px';
}

const spacings = {
  default: '16px 0',
  medium: '32px 0',
  large: '40px 0',
  none: 0,
};

export const DividerStyles = styled.hr<IDividerProps>`
  border: none;
  border-top: ${({ color = colors.ColorNeutral200 }) => `1px solid ${color}`};
  margin: ${({ spacing = 'default' }) => spacings[spacing]};
  ${({ negativeMargin }) => negativeMargin && `margin-inline: ${negativeMargin};`}
`;

export const Divider = ({ spacing, color, negativeMargin }: IDividerProps) => {
  return <DividerStyles spacing={spacing} color={color} negativeMargin={negativeMargin} />;
};
