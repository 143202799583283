import { Flex } from '@ui/flex';
import { Typography, TypographyType } from '@ui/typography';

import { SpinnerStyles } from './Spinner.styles';
import { ISpinnerProps } from './Spinner.types';

export const Spinner = ({ color, size, message, type = TypographyType.BODY_M_REGULAR, ...props }: ISpinnerProps) => {
  return (
    <Flex gap={8} align="center" {...props}>
      <SpinnerStyles color={color} size={size} />
      {message && (
        <Typography type={type} color={color} as="span">
          {message}
        </Typography>
      )}
    </Flex>
  );
};
