import styled from 'styled-components';

export const NoticeStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
`;

export const NoticeImageStyles = styled.img`
  display: block;
  width: 100%;
  max-height: 200px;
  margin-bottom: 24px;
  object-fit: contain;
`;
