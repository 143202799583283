import { useFormContext, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { OptionTypeBase } from 'react-select';

import { Control } from '@ui/control';
import { Skeleton } from '@ui/skeleton';

import { ReactSelectStyles } from './Select.styles';
import { ISelectProps } from './Select.types';
import { DropdownIndicator, ClearIndicator, MultiValueRemove, SelectInput, CustomOption } from './SelectComponents';

export function Select<OptionType extends OptionTypeBase, IsMulti extends boolean>({
  name,
  registerOptions,
  label,
  helperText,
  isMulti,
  direction,
  isClearable,
  isLoading,
  ...props
}: ISelectProps<OptionType, IsMulti>) {
  const { control, getValues } = useFormContext();
  const values = getValues(name);

  return (
    <Controller
      name={name}
      control={control}
      rules={registerOptions}
      render={({ field, fieldState: { error } }) => {
        return (
          <Control
            isErrorShown={!!error}
            label={label}
            error={error?.message}
            name={name}
            direction={direction}
            helperText={helperText}
          >
            {isLoading ? (
              <Skeleton height="40px" />
            ) : (
              <ReactSelectStyles
                classNamePrefix="react-select"
                components={{
                  DropdownIndicator,
                  ClearIndicator,
                  MultiValueRemove,
                  Input: SelectInput,
                  Option: CustomOption,
                }}
                menuPlacement="auto"
                closeMenuOnSelect={!isMulti}
                isMulti={isMulti}
                isClearable={isClearable || isMulti}
                isErrorShown={!!error}
                hasValues={isMulti ? values?.length : !!values}
                inputId={name}
                aria-required={
                  typeof registerOptions?.required === 'boolean' || typeof registerOptions?.required === 'string'
                    ? !!registerOptions?.required
                    : registerOptions?.required?.value
                }
                aria-invalid={!!error}
                aria-labelledby={`${name}-label`}
                aria-describedby={`${name}-error`}
                noOptionsMessage={() => <FormattedMessage id="select.noOptionsMessage" />}
                loadingMessage={() => <FormattedMessage id="select.loadingMessage" />}
                {...field}
                {...props}
              />
            )}
          </Control>
        );
      }}
    />
  );
}
