import { OptionTypeBase, components, IndicatorProps, InputProps, OptionProps } from 'react-select';

import { ReactComponent as Close } from '@ui/assets/icons/close.svg';
import { ReactComponent as ExpandLess } from '@ui/assets/icons/expand-less.svg';
import { ReactComponent as ExpandMore } from '@ui/assets/icons/expand-more.svg';
import { Divider } from '@ui/divider';

export function DropdownIndicator<OptionType extends OptionTypeBase, IsMulti extends boolean>(
  props: IndicatorProps<OptionType, IsMulti>,
) {
  const isMenuOpen = props.selectProps.menuIsOpen;
  return (
    <components.DropdownIndicator {...props}>
      {isMenuOpen ? <ExpandLess /> : <ExpandMore />}
    </components.DropdownIndicator>
  );
}

export function ClearIndicator<OptionType extends OptionTypeBase, IsMulti extends boolean>(
  props: IndicatorProps<OptionType, IsMulti>,
) {
  return (
    <components.ClearIndicator {...props}>
      <Close />
    </components.ClearIndicator>
  );
}

export function MultiValueRemove<OptionType extends OptionTypeBase, IsMulti extends boolean>(
  props: IndicatorProps<OptionType, IsMulti>,
) {
  return (
    <components.MultiValueRemove {...props}>
      <Close />
    </components.MultiValueRemove>
  );
}

export function SelectInput(props: InputProps & { selectProps: Record<string, unknown> }) {
  return (
    <components.Input
      {...props}
      aria-required={props.selectProps['aria-required']}
      data-testid={props.selectProps['data-testid']}
    />
  );
}

export const CustomOption = <OptionType extends OptionTypeBase, IsMulti extends boolean = false>(
  props: OptionProps<OptionType, IsMulti>,
) => (props.data.divider ? <Divider /> : <components.Option {...props} />);
