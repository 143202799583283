import mixpanel from 'mixpanel-browser';
import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react';

import { isProduction } from '@bootstrap/constants/env';
import { ProductsEnum } from '@bootstrap/constants/products';
import { RolesEnum } from '@bootstrap/constants/roles';
import { AccountInfoQuery, useAccountInfoQuery } from '@debtor-portal/types/generated.hooks';

export const CompanyContext = createContext<AccountInfoQuery['accountInfo']>({} as AccountInfoQuery['accountInfo']);

export const CompanyContextProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useAccountInfoQuery();

  const accountInfo = useMemo(() => data?.accountInfo || ({} as AccountInfoQuery['accountInfo']), [data?.accountInfo]);

  useEffect(() => {
    if (accountInfo && isProduction) {
      mixpanel.identify(accountInfo.debtorId);
      mixpanel.people.set({
        $name: accountInfo.contactInfo?.name,
        company: accountInfo.officialName,
        account: ProductsEnum.DEBTOR_PORTAL,
        role: RolesEnum.DEBTOR,
      });
    }
  }, [accountInfo]);

  return <CompanyContext.Provider value={{ ...accountInfo }}>{children}</CompanyContext.Provider>;
};

export const useCompany = () => useContext(CompanyContext);
