import styled from 'styled-components';

import { offsetStyles } from '@ui/offset/Offset';

import { IGridProps } from './Grid.types';

export const GridStyles = styled.div<IGridProps>`
  display: grid;
  grid-template-columns: ${({ template = [12] }) =>
    Array.isArray(template) ? template.map((item) => `${item}fr`).join(' ') : template};
  gap: ${({ gap }) => gap && `${gap}px`};
  column-gap: ${({ columnGap }) => columnGap && `${columnGap}px`};
  row-gap: ${({ rowGap }) => rowGap && `${rowGap}px`};
  align-items: ${({ align }) => (align ? align : undefined)};
  justify-items: ${({ justify }) => (justify ? justify : undefined)};
  padding: 0;
  margin: 0;
  ${offsetStyles}
`;
