import { ColorNegative500, ColorNeutral800 } from '@hub/design-tokens';
import { Typography, TypographyType } from '@ui/typography';

import { ContextMenuItemWrapper } from './ContextMenu.styles';

interface IContextMenuItemProps {
  label: string | JSX.Element;
  onClick: () => void;
  iconLeft: JSX.Element;
  iconRight?: JSX.Element;
  variant?: 'default' | 'negative';
}

export const ContextMenuItem = ({
  label,
  onClick,
  iconLeft,
  iconRight,
  variant = 'default',
}: IContextMenuItemProps) => {
  const isNegative = variant === 'negative';
  return (
    <ContextMenuItemWrapper isNegative={isNegative} onClick={onClick}>
      {iconLeft}
      <Typography type={TypographyType.BODY_M_REGULAR} color={isNegative ? ColorNegative500 : ColorNeutral800}>
        {label}
      </Typography>
      {iconRight}
    </ContextMenuItemWrapper>
  );
};
