import styled from 'styled-components';

import { IContextMenuProps } from './ContextMenu.types';

export const ContextMenuWrapper = styled.div<{ isMobile: boolean; offset: IContextMenuProps['offset'] }>`
  min-width: 300px;
  padding: ${({ offset }) =>
    typeof offset === 'number' ? `${offset}px` : offset?.map((p) => (p !== null ? `${p}px` : p)).join(' ')};
`;

export const ContextMenuItemWrapper = styled.button<{ isNegative: boolean }>`
  background-color: transparent;
  width: 100%;
  border: 2px solid transparent;
  padding: 8px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  border-radius: var(--border-radius-default);
  align-items: center;
  gap: 8px;
  color: ${({ isNegative }) => (isNegative ? 'var(--color-negative-500)' : 'var(--color-neutral-800)')};
  cursor: pointer;

  &:hover {
    background: var(--color-neutral-100);
  }

  &:active {
    background: var(--color-neutral-200);
  }

  &:focus-visible {
    outline: 2px solid var(--color-primary-500);
    outline-offset: -1px;
  }
`;
