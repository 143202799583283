import { useEffect } from 'react';
/**
 * useKeydown
 * @param {string} target - the name(s) of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key down
 */
export function useKeydown(target: string | string[], action?: () => void) {
  useEffect(() => {
    const keys = Array.isArray(target) ? target : [target];
    function onKeypress(event: KeyboardEvent) {
      if (keys.some((key) => event.key === key)) action?.();
    }
    window.addEventListener('keydown', onKeypress);
    return () => window.removeEventListener('keydown', onKeypress);
  }, [action, target]);
}
