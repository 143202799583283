import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({ children }: { children: React.ReactNode }) => {
  const portalRootRef = useRef(document.createElement('div'));

  useEffect(() => {
    const portal = portalRootRef.current;
    document.body.appendChild(portal);

    return () => {
      portal.remove();
    };
  }, []);

  return ReactDOM.createPortal(children, portalRootRef.current);
};
