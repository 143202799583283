import dayjs from 'dayjs';
import { ParamKeyValuePair, useSearchParams } from 'react-router-dom';

import { DATE_FORMAT } from '@bootstrap/constants/date';
import { SelectOptionType } from '@ui/select';

import { useLocalStorage } from './useStorage';

export type IFilterValues = Partial<{
  [key: string]: string | number | Date | null | SelectOptionType | { label: string; value: number } | boolean;
}>;

export interface IUseSearchParamsManager<Values extends IFilterValues = IFilterValues> {
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit: string | ParamKeyValuePair[] | Record<string, string | string[]> | URLSearchParams,
    navigateOptions?: { replace?: boolean | undefined; state?: any } | undefined,
  ) => void;
  getSearchParam: (key: string) => string | undefined;
  setSearchParam: (key: string, value?: string | number | null) => void;
  clearFormFilters: (reset?: () => void) => void;
  updateFormFilterValues: (values: Values) => void;
}

export interface UseFormFilterManagerProps {
  /**
   * @description
   * Pass it if you want to save filters to the local storage.
   */
  persistKey?: string;
}

/**
 * TODO: Replace this manager usage for all code places to one of mentioned bellow
 * @deprecated Please use one of (
 *    useLocalStorageFilterManager, useSessionFilterManager, useUrlAndLocalStorageFilterManager,
 *    useUrlFilterManager
 * ) as this manager is unsecure and leads to potential bugs and issues
 * @param persistKey
 */
export function useFormFilterManager<Values extends IFilterValues = IFilterValues>({
  persistKey,
}: UseFormFilterManagerProps = {}): IUseSearchParamsManager<Values> {
  const [searchParams, setSearchParams] = useSearchParams();
  const [localStorage, setLocalStorage] = useLocalStorage<string>(persistKey, '');

  const setSearchParamsAndLocalStorage = (
    nextInit: string | ParamKeyValuePair[] | Record<string, string | string[]> | URLSearchParams,
    navigateOptions?: { replace?: boolean | undefined; state?: any } | undefined,
  ) => {
    setSearchParams(nextInit, navigateOptions);
    setLocalStorage(new URLSearchParams(nextInit as any).toString());
  };

  const getSearchParam = (key: string): string | undefined => {
    const value = searchParams.get(key) ?? new URLSearchParams(localStorage).get(key) ?? '';
    return value.length ? value : undefined;
  };

  function setSearchParam(key: string, value?: string | number | null): void {
    const localStorageParams = new URLSearchParams(localStorage);
    if (value === null || value === undefined) {
      localStorageParams.delete(key);
      return searchParams.delete(key);
    }
    const valueString = typeof value === 'number' ? (value ? value.toString() : '') : value;

    if (valueString.length) {
      searchParams.set(key, valueString);
      localStorageParams.set(key, valueString);
    } else {
      searchParams.delete(key);
      localStorageParams.delete(key);
    }
    setSearchParams(searchParams);
    setLocalStorage(localStorageParams.toString());
  }

  const clearFormFilters = (reset?: () => void): void => {
    setSearchParamsAndLocalStorage({});
    reset?.();
  };

  const updateFormFilterValues = (values: Values) => {
    const localStorageValues = new URLSearchParams(localStorage).entries();
    [...localStorageValues, ...Object.entries(values)].forEach(([key, value]) => {
      if (!value) {
        searchParams.delete(key);
      } else if (typeof value === 'object' && 'value' in value && 'label' in value) {
        setSearchParam(key, value.value);
      } else {
        const stringValue =
          typeof value === 'number' || typeof value === 'boolean'
            ? value.toString()
            : value instanceof Date
              ? dayjs(value).format(DATE_FORMAT)
              : value;

        searchParams.set(key, stringValue);
      }
    });
    searchParams.delete('offset');
    setSearchParamsAndLocalStorage(searchParams);
  };

  return {
    searchParams,
    setSearchParams: setSearchParamsAndLocalStorage,
    getSearchParam,
    setSearchParam,
    clearFormFilters,
    updateFormFilterValues,
  };
}
