import styled, { CSSProperties, css } from 'styled-components';

export type OffsetValue = number | null;

export interface IOffsetProps {
  /**
   * @description
   * OffsetValue = offset applies to all four sides
   *
   * [OffsetValue, OffsetValue] = [top and bottom, left and right]
   *
   * [OffsetValue, OffsetValue, OffsetValue] = [top, left and right, bottom]
   *
   * [OffsetValue, OffsetValue, OffsetValue, OffsetValue] = [top, right, bottom, left]
   */
  offset?:
    | OffsetValue
    | [OffsetValue, OffsetValue]
    | [OffsetValue, OffsetValue, OffsetValue]
    | [OffsetValue, OffsetValue, OffsetValue, OffsetValue];
  offsetLeft?: OffsetValue;
  offsetRight?: OffsetValue;
  offsetTop?: OffsetValue;
  offsetBottom?: OffsetValue;
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
  role?: string;
  id?: string;
  maxWidth?: CSSProperties['maxWidth'];
}

export const offsetStyles = css<Omit<IOffsetProps, 'children'>>`
  margin: ${({ offset }) =>
    typeof offset === 'number' ? `${offset}px` : offset?.map((p) => (p !== null ? `${p}px` : p)).join(' ')};
  margin-left: ${({ offsetLeft }) => offsetLeft && `${offsetLeft}px`};
  margin-right: ${({ offsetRight }) => offsetRight && `${offsetRight}px`};
  margin-top: ${({ offsetTop }) => offsetTop && `${offsetTop}px`};
  margin-bottom: ${({ offsetBottom }) => offsetBottom && `${offsetBottom}px`};
  max-width: ${({ maxWidth }) => maxWidth};
`;

const OffsetStyles = styled.div<IOffsetProps>`
  ${offsetStyles}
`;

export const Offset = ({ children, ...props }: IOffsetProps) => {
  return <OffsetStyles {...props}>{children}</OffsetStyles>;
};
