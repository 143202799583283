import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MainNavigationStyles = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 16px;
  gap: 24px;
  width: var(--main-navigation-desktop-width);
  height: 100vh;
  overflow: auto;
  background-color: var(--color-white);
  border-right: 1px solid var(--color-neutral-200);

  header {
    padding: 8px;
  }
`;

/** header */
export const MainNavigationTopBar = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-main-navigation);
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100vw;
  max-width: 100%;
  height: var(--main-navigation-mobile-top-bar-height);
  padding: 8px 16px;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-neutral-200);
  box-shadow: var(--shadow-default);
  align-items: center;
`;

export const MainNavigationLogoStyles = styled.img`
  max-width: 160px;
  max-height: 80px;
  object-fit: contain;

  @media (max-width: 768px) {
    max-height: 48px;
    padding: 8px;
  }
`;

/** menu */
export const MainNavigationMobileMenu = styled.div`
  --header-height: 73px;
  --paddings: calc(16px * 2);

  display: grid;
  grid-template-rows: 1fr auto;
  gap: 24px;
  height: calc(100vh - var(--header-height) - var(--paddings));

  @supports (height: 100dvh) {
    height: calc(100dvh - var(--header-height) - var(--paddings));
  }
`;

export const MainNavigationMenuStyles = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
`;

export const MainNavigationTitleStyles = styled.div`
  padding: 8px;
  margin-top: 16px;
  color: var(--color-neutral-600);
  font-family: var(--typography-body);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: start;
`;

export const MainNavigationItemStyles = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['isMobile'].includes(prop),
})<{ isMobile: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: 24px 1fr;
  color: var(--color-neutral-800);
  gap: 8px;
  align-items: center;
  padding: 8px;
  width: 100%;
  font-family: var(--typography-body);
  font-style: normal;
  font-weight: 400;
  font-size: ${({ isMobile }) => (isMobile ? '16px' : '14px')};
  line-height: ${({ isMobile }) => (isMobile ? '24px' : '20px')};
  text-align: start;
  word-break: break-word;
  hyphens: auto;
  appearance: none;
  background-color: transparent;
  border-radius: var(--border-radius-default);
  border: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: var(--color-neutral-800);
    background: var(--color-neutral-100);
  }

  &:active {
    color: var(--color-primary-700);
    font-weight: 700;
    background: var(--color-primary-200);
  }

  &:focus-visible {
    outline: auto;
    outline-offset: -2px;
  }

  &[aria-current='page'] {
    color: var(--color-primary-700);
    font-weight: 700;
    background: var(--color-primary-100);

    &:hover {
      background: var(--color-primary-200);
    }
  }

  &[aria-expanded='true'] {
    color: var(--color-primary-700);
    font-weight: 700;
    background: transparent;

    &:hover {
      background: var(--color-neutral-100);
    }
  }

  /* The pseudo element has the same content and hover style, so it pre-sets the width of the element and visibility: hidden hides the pseudo element from actual view. */
  span::after {
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
`;
