import { useState, useEffect } from 'react';

/**
 * Optional chaining is used for tests to pass: https://jestjs.io/docs/manual-mocks#mocking-methods-which-are-not-implemented-in-jsdom.
 */
const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(window.matchMedia?.(query).matches);

  useEffect(() => {
    const media = window.matchMedia?.(query);
    if (media?.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media?.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};

/**
 * @example const isMobile = useMobile();
 */
export const useMobile = (): boolean => useMediaQuery('(max-width: 768px)');
export const useTablet = (): boolean => useMediaQuery('(max-width: 1024px)');
