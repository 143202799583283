import { ColorNegative600 } from '@hub/design-tokens/colors';
import { ReactComponent as Error } from '@ui/assets/icons/error.svg';
import { Grid } from '@ui/grid';
import { Typography, TypographyType } from '@ui/typography';

export const ErrorMessage = ({ message, id }: { message: string | JSX.Element; id?: string }) => {
  return (
    <Grid gap={8} template="20px 1fr">
      <Error width={20} height={20} color={ColorNegative600} />
      <Typography color={ColorNegative600} type={TypographyType.BODY_S} as="span" id={id}>
        {message}
      </Typography>
    </Grid>
  );
};
