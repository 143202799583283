import { IDrawerProps } from '@ui/drawer';

import { StickyFooterStyles } from './StickyFooter.styles';

/**
 * @description
 * Do not wrap `StickyFooter` in a container with paddings/margins.
 */
export const StickyFooter = ({
  children,
  padding,
}: {
  children: React.ReactNode;
  padding?: IDrawerProps['padding'];
}) => {
  return <StickyFooterStyles padding={padding}>{children}</StickyFooterStyles>;
};
