import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowBack } from '@ui/assets/icons/arrow-back.svg';
import { ReactComponent as Duplicate } from '@ui/assets/images/duplicate-file.svg';
import { Button } from '@ui/button';
import { Notice } from '@ui/notice';
import { Offset } from '@ui/offset';

export const NotFoundPage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <Offset offset={[40, 24]}>
      <Notice
        title={formatMessage({ id: 'pageNotFound.title' })}
        description={<FormattedMessage id="pageNotFound.description" />}
        image={<Duplicate width={100} height={100} />}
        actions={[
          <Button variant="default" iconLeft={<ArrowBack />} onClick={() => navigate(-1)} key="back">
            <FormattedMessage id="action.back" />
          </Button>,
        ]}
      />
    </Offset>
  );
};
