import { ColorNeutral500 } from '@hub/design-tokens/colors';
import { ErrorMessage } from '@ui/error-message';
import { Grid } from '@ui/grid';
import { Typography, TypographyType } from '@ui/typography';

import { IControlProps } from './Control.types';

export const Control = ({
  children,
  label,
  name,
  error,
  isErrorShown,
  helperText,
  direction = 'column',
}: IControlProps) => {
  return (
    <Grid gap={8} template={direction === 'row' ? [1, 1] : undefined} align="center">
      {label && (
        <Typography type={TypographyType.LABEL} as="label" htmlFor={name} id={`${name}-label`}>
          {label}
          {helperText && (
            <>
              {' '}
              <Typography type={TypographyType.BODY_S} as="span" color={ColorNeutral500}>
                ({helperText})
              </Typography>
            </>
          )}
        </Typography>
      )}
      {children}
      {direction === 'row' && <div />}
      {isErrorShown && error && <ErrorMessage message={error} id={`${name}-error`} />}
    </Grid>
  );
};
