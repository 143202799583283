import { toast, Toaster as ReactHotToaster, ToastBar, resolveValue } from 'react-hot-toast';

import { Toast } from './Toast';

/**
 * @description
 * This component will render all toasts. It must be added at the top level of application render tree.
 */
export const Toaster = () => {
  const result = window?.matchMedia('(max-width: 560px)');
  return (
    <ReactHotToaster
      gutter={8}
      position="top-right"
      toastOptions={{ duration: 3000 }}
      containerStyle={{
        inset: result.matches ? 8 : 16,
      }}
    >
      {(t) => {
        const toastType = t.type === 'blank' || t.type === 'loading' || t.type === 'custom' ? 'info' : t.type;
        const ariaProps = {
          role: toastType === 'error' ? 'alert' : 'status',
          'aria-live': toastType === 'error' ? 'assertive' : 'polite',
        };
        return (
          <ToastBar
            toast={t}
            style={{
              padding: 0,
              boxShadow: 'none',
              width: 'min(480px, 100%)',
              maxWidth: '480px',
            }}
          >
            {() => (
              <Toast
                type={toastType}
                message={resolveValue(t.message, t)}
                onClose={() => toast.dismiss(t.id)}
                {...ariaProps}
              />
            )}
          </ToastBar>
        );
      }}
    </ReactHotToaster>
  );
};

export { toast };
