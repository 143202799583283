// TODO: Use typed singleton creation
interface Configuration extends Record<string, string> {
  AUTH_SERVER_URL: string;
  ENVIRONMENT: string;
}

function createDynamicConfiguration() {
  const defaultConfiguration: Configuration = {
    AUTH_SERVER_URL: process.env.NX_PUBLIC_AUTH_SERVER_URL || '',
    ENVIRONMENT: process.env.NX_PUBLIC_ENV || '',
  };

  return defaultConfiguration;
}

const config = createDynamicConfiguration();

export default config;
