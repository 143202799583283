import { useState, createContext, useContext, useEffect, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import config from '@debtor-portal/configs/constants';

export type AuthData = {
  authenticated?: boolean;
  token?: string;
};

const MagicLinkAuthContext = createContext<AuthData | undefined>(undefined);

export const UseMagicLinkAuthContext = (): AuthData => {
  const authContext = useContext(MagicLinkAuthContext);

  return {
    token: authContext?.token,
    authenticated: authContext?.authenticated || false,
  };
};

export interface MagicLinkAuthProviderProps {
  children: ReactNode;
  errorComponent?: ReactNode;
}

const getTokenFromCookie = (): string | undefined => {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith('token='))
    ?.split('=')[1];
};

const setTokenCookie = (token: string, expiresTimestamp: number): void => {
  const expiresDate = new Date(expiresTimestamp);
  document.cookie = `token=${token}; expires=${expiresDate.toUTCString()}`;
};

const clearTokenCookie = (): void => {
  document.cookie = `token=; max-age=0`;
};

export const logout = (): void => {
  clearTokenCookie();
  window.location.href = config.AUTH_SERVER_URL;
};

export const MagicLinkAuthProvider = ({ children, errorComponent = null }: MagicLinkAuthProviderProps) => {
  const [error] = useState(false);
  const [authData, setAuthData] = useState<AuthData>({ token: undefined, authenticated: false });

  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const expiresTimestamp = searchParams.get('expires');

  if (token && !authData.authenticated) {
    setAuthData({ token, authenticated: true });
    setTokenCookie(token, Number(expiresTimestamp));
  }
  if (!authData.authenticated) {
    const cookieToken = getTokenFromCookie();
    if (cookieToken) {
      setAuthData({ token: cookieToken, authenticated: true });
    } else {
      // if not authorized - redirect to login page
      window.location.href = config.AUTH_SERVER_URL;
    }
  }

  useEffect(() => {
    if (token && authData.authenticated) {
      searchParams.delete('token');
      searchParams.delete('expires');
      setSearchParams(searchParams);
      return;
    }
  }, [token, authData, setSearchParams, searchParams]);

  return (
    <MagicLinkAuthContext.Provider value={authData}>
      {error && errorComponent}
      {!error && authData?.authenticated && children}
    </MagicLinkAuthContext.Provider>
  );
};
