import { useMobile } from '@bootstrap/hooks/useMedia';

import { ContextMenuWrapper } from './ContextMenu.styles';
import { IContextMenuProps } from './ContextMenu.types';

export const ContextMenu = ({ children, offset = 8 }: IContextMenuProps) => {
  const isMobile = useMobile();
  return (
    <ContextMenuWrapper isMobile={isMobile} offset={offset}>
      {children}
    </ContextMenuWrapper>
  );
};
