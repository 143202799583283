import styled, { css } from 'styled-components';

import { IDrawerProps } from './Drawer.types';

const transitionSpeed = '0.3s';

const widths = {
  xs: '25%',
  s: 'calc(100vw / 3)',
  m: '50%',
  l: 'calc(100vw / 3 * 2)',
  xl: '100%',
};

export const DrawerContainerStyles = styled.div`
  isolation: isolate;

  @media (max-width: 768px) {
    isolation: initial;
  }
`;

export const DrawerStyles = styled.div<Pick<IDrawerProps, 'isOpen' | 'size' | 'fullscreen'>>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: ${({ size, fullscreen }) => (fullscreen ? '100vw' : widths[size])};
  max-width: 100%;
  background: var(--color-white);
  overflow: auto;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  box-shadow: ${({ isOpen }) => isOpen && 'var(--shadow-large)'};
  transition:
    transform ${transitionSpeed} ease,
    width ${transitionSpeed} ease;
  z-index: 0;

  @media (max-width: 768px) {
    width: 100%;
    z-index: var(--z-index-modal);
  }
`;

export const DrawerContentStyles = styled.div<Pick<IDrawerProps, 'size' | 'fullscreen' | 'footer'>>`
  width: ${({ size, fullscreen }) => (fullscreen ? widths[size] : '100%')};
  transition: width ${transitionSpeed} ease;

  @media (max-width: 768px) {
    width: 100%;
  }

  ${({ footer }) =>
    footer &&
    css`
      display: grid;
      grid-template-rows: auto 1fr auto;
      min-height: 100vh;

      @supports (min-height: 100dvh) {
        min-height: 100dvh;
      }
    `}
`;

export const DrawerHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  padding: 24px;
  border-bottom: 1px solid var(--color-neutral-300);
  z-index: 1;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const DrawerContent = styled.div<{ padding?: IDrawerProps['padding'] }>`
  padding: 24px;

  @media (max-width: 768px) {
    padding: 16px;
  }

  @media screen {
    padding: ${({ padding }) =>
      padding !== undefined &&
      (typeof padding === 'number' ? `${padding}px` : padding?.map((p) => (p !== null ? `${p}px` : p)).join(' '))};
  }
`;
