import { isValidElement } from 'react';

import { Flex } from '@ui/flex';
import { Offset } from '@ui/offset';
import { Typography, TypographyType } from '@ui/typography';

import { NoticeStyles, NoticeImageStyles } from './Notice.styles';
import { INoticeProps } from './Notice.types';

export const Notice = ({ title, image, description, actions, hasImage = true }: INoticeProps) => {
  return (
    <NoticeStyles>
      {!hasImage ? null : isValidElement(image) ? (
        <Offset offsetBottom={24}>{image}</Offset>
      ) : (
        <NoticeImageStyles src={image ? (image as string) : '../assets/images/list.svg'} alt="" />
      )}
      <Typography type={TypographyType.HEADING_M} align="center">
        {title}
      </Typography>
      {description && (
        <Typography type={TypographyType.BODY_M_REGULAR} align="center" offsetTop={16} as="div">
          {description}
        </Typography>
      )}
      {actions && (
        <Flex gap={16} align="center" justify="center" offsetTop={24} wrap="wrap-reverse">
          {actions}
        </Flex>
      )}
    </NoticeStyles>
  );
};
