import styled, { css } from 'styled-components';

import { ISheetProps } from './Sheet.types';

const transitionSpeed = '0.3s';

export const SheetContainerStyles = styled.div`
  isolation: isolate;

  @media (max-width: 768px) {
    isolation: initial;
  }
`;

export const SheetStyles = styled.div<Pick<ISheetProps, 'isOpen'>>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  max-height: 85%;
  width: 100%;
  background: var(--color-white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow-y: auto;
  transform: translate(0, 100%);
  transition: transform ${transitionSpeed} ease;
  z-index: 0;

  @media (max-width: 768px) {
    z-index: var(--z-index-modal);
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translate(0, 0);
      box-shadow: var(--shadow-large);
    `}
`;

export const SheetHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  padding: 16px;
  border-bottom: 1px solid var(--color-neutral-300);
  z-index: 1;
`;

export const SheetContent = styled.div<{ padding?: ISheetProps['padding'] }>`
  padding: ${({ padding }) =>
    padding !== undefined
      ? typeof padding === 'number'
        ? `${padding}px`
        : padding?.map((p) => (p !== null ? `${p}px` : p)).join(' ')
      : '16px'};
`;
