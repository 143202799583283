import { useId } from 'react';
import FocusLock from 'react-focus-lock';
import { useIntl } from 'react-intl';

import { useKeydown } from '@bootstrap/hooks/useKeydown';
import { useLockBodyScroll } from '@bootstrap/hooks/useLockBodyScroll';
import { ColorNeutral600 } from '@hub/design-tokens/colors';
import { ReactComponent as Close } from '@ui/assets/icons/close.svg';
import { Backdrop } from '@ui/backdrop';
import { Button } from '@ui/button';
import { Flex } from '@ui/flex';
import { Offset } from '@ui/offset';
import { Portal } from '@ui/portal';
import { StickyFooter } from '@ui/sticky-footer';
import { Typography, TypographyType } from '@ui/typography';

import { SheetContainerStyles, SheetStyles, SheetHeader, SheetContent } from './Sheet.styles';
import { ISheetProps } from './Sheet.types';

export const Sheet = ({ isOpen, subtitle, title, onClose, children, footer, padding }: ISheetProps) => {
  const { formatMessage } = useIntl();
  const ariaLabelledBy = useId();
  const ariaDescribedBy = subtitle ? `${ariaLabelledBy}-description` : undefined;

  useLockBodyScroll(isOpen);
  useKeydown('Escape', onClose);

  return (
    <Portal>
      <FocusLock disabled={!isOpen} returnFocus>
        <SheetContainerStyles>
          <Backdrop isOpen={isOpen} onClose={onClose} />
          <SheetStyles
            isOpen={isOpen}
            role="dialog"
            aria-labelledby={ariaLabelledBy}
            aria-describedby={ariaDescribedBy}
            aria-hidden={isOpen ? 'false' : 'true'}
          >
            {title && (
              <SheetHeader>
                <Flex gap={8} align="center" justify="space-between">
                  <div>
                    <Typography type={TypographyType.HEADING_S} as="h3" id={ariaLabelledBy}>
                      {title}
                    </Typography>
                    {subtitle && (
                      <Offset offsetTop={8}>
                        <Typography
                          type={TypographyType.HEADING_XS}
                          color={ColorNeutral600}
                          id={ariaDescribedBy}
                          as="div"
                        >
                          {subtitle}
                        </Typography>
                      </Offset>
                    )}
                  </div>
                  {onClose && (
                    <Button
                      variant="secondary"
                      iconLeft={<Close />}
                      onClick={onClose}
                      aria-label={formatMessage({ id: 'action.close' })}
                    />
                  )}
                </Flex>
              </SheetHeader>
            )}
            <SheetContent padding={padding}>{children}</SheetContent>
            {footer && <StickyFooter padding={padding}>{footer}</StickyFooter>}
          </SheetStyles>
        </SheetContainerStyles>
      </FocusLock>
    </Portal>
  );
};
